import styled from "styled-components"

const media = {
  desktop: "@media(min-width: 1000px)",
}

const ProjectsBanner = styled.div`
  &:after {
    content: "";
    display: block;
    height: ${props => (props.parallax ? "60vh" : "80vh")};
    width: 100%;
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: ${props => (props.parallax ? "fixed" : "scroll")};
  }
`
const ProjectsText = styled.div`
  position: absolute;
  z-index: 1;
  padding-left: 40px;
  top: 65%;
  color: black;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`

const ImageDiv = styled.div`
  content: "";
  display: block;
  height: ${props => (props.parallax ? "60vh" : "80vh")};
  width: ${props => (props.flex ? "25%" : "50%")};
  height: 700px;
  margin-top: ${props => (props.pt ? "125px" : "")};
  background-image: url(${props => props.img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: ${props => (props.parallax ? "fixed" : "scroll")};
`

export { ProjectsBanner, ProjectsText, ImageDiv }
