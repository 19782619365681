import React from "react"
import Layout from "../components/Layout"
import { ImageDiv } from "../styles/ProjectStyles"

const reset = () => {
  return (
    <Layout>
      <section style={{ position: "relative" }}></section>
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          backgroundColor: "#1d242a",
        }}
      >
        <ImageDiv flex pt img={"/art02.jpg"} />
        <ImageDiv flex pt img={"/art03.jpeg"} />
        <ImageDiv flex pt img={"/art04.jpg"} />
        <ImageDiv flex pt img={"/art05.jpeg"} />

        {/*  <div
          style={{
            backgroundColor: "black",
            width: "100%",
            height: "700px",
            justifyContent: "center",
            disply: "flex",
            flexFlow: "row",
          }}
        > */}
        {/* <div
          style={{
            
            backgroundColor: "seagreen",
            width: "25%",
            height: "300px",
          }}
        />
        <div
          style={{
            
            backgroundColor: "red",
            width: "25%",
            height: "300px",
          }}
        />
        <div
          style={{
            
            backgroundColor: "hotpink",
            width: "25%",
            height: "300px",
          }}
        />
        <div
          style={{
            
            backgroundColor: "orange",
            width: "25%",
            height: "300px",
          }}
        /> */}
      </div>
      <div
        style={{
          backgroundColor: "#1d242a",
          width: "100%",
          height: "300px",
          justifyContent: "center",
          alignItems: "center",
          padding: "70px",
        }}
      >
        <p
          style={{
            color: "white",
            fontFamily: "Inconsolata",
            fontSize: "25px",
            margin: "0%",
            textAlign: "left",
          }}
        >
          <h2 style={{ margin: "0" }}>Art: </h2> In my free time I like to make
          art. I first make my drawings as sketches on my IPad Pro, mostly using
          the programs Procreate and Abobe Fresco. The colorization is then
          either watercolor or digital. I like to combine playful flora and
          fauna in my drawings.
        </p>
      </div>
      {/* </div> */}
    </Layout>
  )
}

export default reset
